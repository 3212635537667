import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { withRouter, GLForm, FormItemsLayout, FormItemInModalParams, FormHelper, GLFormComponentProps, GLGlobal, GLUtil, RoleName, GLLocale, MessageHelper, NotificationType, connect, StateType, validateLoginId } from "gl-commonui";
import { Form, Row, Col, Button, Input, Spin } from "antd-min";
import { GSAdminLocale, SchoolLocale } from '../../../locales/localeid';
import { SchoolPathConfig as PathConfig } from "../../../config/pathconfig";
import { getInfoByCode, redeemCode } from '../../../states/school//inviting';
import { RedeemProps } from '../../../service/users';
import { fmtMsg, GLRegistrationLocalizationHelper, InvitationChannel, inviteEmailValidation, LoginIdType } from '@app/util';
import { InvitationResponse } from '@app/service/school/invitation';
import { GLLoginIdInput } from '@app/components/register/gl-login-input';
import { GLRegistrationLocalization } from '@app/components/register/Model';
import { Result } from '@app/components/result/result';
import { FormItem } from '@app/page/cims/component/cims-consts';
import "@app/components/register/index.less";
import "./redeem.less";

interface RedeemExistingProps extends RouteComponentProps<any> {
    getInfoByCode: (param, callback) => void
    redeemCode: (param, callback) => void
}
interface RedeemExistingStates {
    disabled?: boolean;
    loading: boolean;
}

@withRouter
@GLForm.create()
@connect(({oidc: { loginInfo }}: StateType) => ({}), {
    getInfoByCode,
    redeemCode
})
export class RedeemExistingPage extends Component<RedeemExistingProps & GLFormComponentProps, RedeemExistingStates> {
    code = GLUtil.queryParse().code
    codeId
    email
    invitationType
    codeInfo: Partial<InvitationResponse> = {}
    loginId
    error = false;
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            loading: true
        };
    }

    componentWillReceiveProps(nextProps: any, nextContext: any) {
        const { setFieldsValue } = this.props.form;
        nextProps.location !== this.props.location && setFieldsValue({ invitationCode:  GLUtil.queryParse().code });
    }

    componentWillMount() {
        try {
            this.props.getInfoByCode({invitationCode: this.code}, this.callback.bind(this));
        } catch {
            this.error = true;
            this.setState({loading: false});
        }
    }

    callback(data) {
        this.codeId = data.id;
        this.invitationType = data.invitationType;
        this.codeInfo = data;
        this.setState({loading: false});
    }

    redeemCallback(success: boolean) {
        if(success) {
            this.props.history.push({ pathname: PathConfig.Invited, search: GLUtil.queryStringify({ email: this.email, code: this.code }) });
        } else {
            this.setState({ disabled: false});
        }
    }

    redeem(user, invitationChannel: InvitationChannel) {
        const params = {
            email: user.email,
            password: user.password,
            invitationCodeType: this.invitationType,
            invitationCodeId: this.codeId,
            invitationChannel
        };
        this.props.redeemCode(params, this.redeemCallback.bind(this));
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ disabled: true });
                const email = values.email.type === LoginIdType.Email ? values.email.email : `${values.email.countryCode}${values.email.phone}`;
                const channel = values.email.type === LoginIdType.Email ? InvitationChannel.Email : InvitationChannel.Phone;
                this.email = email;
                this.redeem({...values, email}, channel);
            }
        });
    }

    renderLoginIdFormItem = (emailParams: FormItemInModalParams) => {
        const { getFieldDecorator } = this.props.form;
        const registrationLocalization: GLRegistrationLocalization = GLRegistrationLocalizationHelper.getGLRegistrationLocalization(
            this.codeInfo.enablePhoneRegistration
        );
        return (
            <FormItem
                label={fmtMsg({
                    id: this.codeInfo.enablePhoneRegistration
                        ? GSAdminLocale.RegistrationFormEmailPhone
                        : GSAdminLocale.RegistrationFormEmail
                })}
                required
            >
                {getFieldDecorator(emailParams.fieldName, {
                    initialValue: {
                        type: LoginIdType.Email,
                        country: "US",
                        countryCode: "+1",
                        phone: "",
                        email: ""
                    },
                    rules: [
                        {
                            validator: validateLoginId(registrationLocalization)
                        },
                        {
                            required: true,
                            message: fmtMsg({
                                id: this.codeInfo.enablePhoneRegistration
                                    ? GSAdminLocale.RegistrationFormEmailPhoneRequired
                                    : GSAdminLocale.RegistrationFormEmailReq
                            })
                        }
                    ]
                })(
                    <GLLoginIdInput
                        onChange={value => (this.loginId = value)}
                        dualMode={this.codeInfo.enablePhoneRegistration}
                        disabled={false}
                        placeholder={" "}
                    />
                )}
            </FormItem>
        );
    };

    renderError = () => {
       return <Result
            type="warning"
            textId={GSAdminLocale.RegistrationRedeemErrTitle}
            extra={
            <Button type="primary" key="goBack" onClick={() => this.props.history.goBack()}>
                {fmtMsg({ id: GSAdminLocale.RegistrationRedeemErrDesc })}
            </Button>
        }/>
    }

    render() {
        const form = this.props.form;
        const renderFormItem = FormHelper.renderFormItem;
        const emailParams: FormItemInModalParams = {
            form: form,
            localeId: SchoolLocale.RedeemExistingEmail,
            fieldName: RedeemProps.email,
            formControl: <Input autoComplete="new-email" />,
            required: true,
            rules: [{
                validator: (
                    rule: any,
                    value: string,
                    callback: (msg?: string) => void
                ) => {
                    inviteEmailValidation(value, callback);

                    callback();
                },
            },]
        }

        const passwordParams: FormItemInModalParams = {
            form: form,
            localeId: SchoolLocale.RedeemExistingPassword,
            fieldName: RedeemProps.password,
            formControl: <Input type="password" autoComplete="new-password" />,
            required: true,
        }

        return <div className="redeem-container">
            <Row className='registerHeader'>{GLGlobal.intl.formatMessage({id: SchoolLocale.RedeemExistingTitle})}</Row>
            <Row className='register'>
            {this.state.loading ? (
                        <Spin spinning={this.state.loading} />
                    ) : this.error ? (
                        this.renderError()
                    ) : (
                        <GLForm
                            form={form}
                            onSubmit={this.handleSubmit.bind(this)}
                        >
                            <FormItemsLayout>
                                {this.renderLoginIdFormItem(emailParams)}
                                {renderFormItem(passwordParams)}
                                <Form.Item>
                                    <div className="gl-center-h">
                                        <Button
                                            className="invitationButton"
                                            disabled={this.state.disabled}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {GLGlobal.intl.formatMessage({
                                                id: SchoolLocale.RedeemBtn
                                            })}
                                        </Button>
                                    </div>
                                </Form.Item>
                            </FormItemsLayout>
                        </GLForm>
                    )}
            </Row>
        </div>
    }
}
